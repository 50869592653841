var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-chart",
    {
      attrs: {
        forceFit: true,
        height: _vm.height,
        data: _vm.data,
        scale: _vm.scale,
        onClick: _vm.handleClick,
      },
    },
    [
      _c("v-tooltip", { attrs: { showTitle: false, dataKey: "item*percent" } }),
      _c("v-axis"),
      _c("v-legend", { attrs: { dataKey: "item" } }),
      _c("v-pie", {
        attrs: {
          position: "percent",
          color: "item",
          "v-style": _vm.pieStyle,
          label: _vm.labelConfig,
        },
      }),
      _c("v-coord", { attrs: { type: "theta" } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }