var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { style: { padding: "0" } },
    [
      _c("h4", { style: { marginBottom: "20px" } }, [
        _vm._v(_vm._s(_vm.title)),
      ]),
      _c(
        "v-chart",
        {
          ref: "chart",
          attrs: {
            forceFit: true,
            height: _vm.height,
            data: _vm.dataSource,
            scale: _vm.scale,
          },
        },
        [
          _c("v-tooltip", { attrs: { shared: false } }),
          _c("v-axis"),
          _c("v-line", {
            attrs: {
              position: "x*y",
              size: _vm.lineSize,
              color: _vm.lineColor,
            },
          }),
          _c("v-area", { attrs: { position: "x*y", color: _vm.color } }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }