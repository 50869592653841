var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "chart-trend" }, [
    _vm._v("\n  " + _vm._s(_vm.term) + "\n  "),
    _c("span", [_vm._v(_vm._s(_vm.rate) + "%")]),
    _c(
      "span",
      { class: ["trend-icon", _vm.trend] },
      [_c("a-icon", { attrs: { type: "caret-" + _vm.trend } })],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }